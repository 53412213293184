import React from "react";
import "./errorpage.css";

const ErrorPage = () => {
  return (
    <div>
      <h2 className="error">404 Page Not Found.</h2>
    </div>
  );
};

export default ErrorPage;
